
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { isInteger } from "@/tools";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import MaterialShadeAutocomplete from "@/components/General/Autocompletes/Equipments/MaterialShadeAutocomplete.vue";

import {
  CalibrationBlockCategory,
  CalibrationBlockItem,
  V2CalibrationBlockItem,
} from "@/domain";

import { IV2CalibrationBlockService } from "@/services";

@Component({
  components: {
    UpdateUi,
    MaterialShadeAutocomplete,
  },
  data: () => {
    return {
      CalibrationBlockCategory,
      isInteger
    };
  },
})
export default class V2BlockUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: CalibrationBlockItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get v2CalibrationBlockService() {
    return this.container.resolve<IV2CalibrationBlockService>(
      S.V2_CALIBRATION_BLOCK_SERVICE
    );
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  dimensionValue: number | null = null;
  materialShadeId: string | null = null;
  celerity: number | null = null;
  pvNumber: string | null = null;

  mounted() {
    this.serialNumber = this.item.serialNumber;
    this.dimensionValue = this.item.dimensionValue;
    this.celerity = this.item.celerity;
    this.materialShadeId = this.item.materialShade.id;
    this.pvNumber = this.item.pvNumber;
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1 &&
      this.item.serialNumber.toUpperCase() != serialNumber.toUpperCase()
    );
  }

  async update() {
    try {
      this.loading = true;
      const block = await this.v2CalibrationBlockService.update(this.item.id, {
        serialNumber: this.serialNumber!,
        dimensionValue: this.dimensionValue!,
        materialShadeId: this.materialShadeId!,
        celerity: this.celerity!,
        pvNumber: this.pvNumber!,
        disabled: this.item.disabled,
      });
      this.$emit("update", V2CalibrationBlockItem.fromDetails(block));
    } finally {
      this.loading = false;
    }
  }
}
